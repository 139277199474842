<template>
  <div>
    <Grid
      v-if="gridOptions"
      :grid-options="gridOptions"
      :default-params="defaultParams"
    ></Grid>
  </div>
</template>

<script>
import Grid from "../components/Grid"
import getGridOptions from "../components/Grid/gridOptions"
export default {
  components: {
    Grid,
  },
  data() {
    return {
      gridOptions: null,
      defaultParams: {
        annotation__status_codes__is_violation: true,
      },
    }
  },
  beforeMount() {
    const gridOptions = getGridOptions("admin", {
      sortBy: "updated_date",
      show: ["updated_date"],
    })
    this.gridOptions = gridOptions
  },
}
</script>
